.radio_option {
  color: #000;
  display: flex;
  flex-direction: column;
  padding: 1.5em;
  margin: 0.5em;
  opacity: 1.0;
  white-space: normal;
  border: 0.01em solid #000;
}

.radio_option > div {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.radio_option h4 {
  font-size: 1.2em;
  font-weight: bold;
  margin-bottom: 0.5em;
  text-decoration: underline;
}

.radio_option.not_selected {
  opacity: 0.4;
}
